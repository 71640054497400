<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="LAMI Logo"
          class="shrink mr-2"
          contain
          src="@/assets/lami_logo_black.png"
          transition="scale-transition"
          width="40"
        />
        <h3>LAMI MEMORY VERSES</h3>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <router-view />
      <app-footer></app-footer>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
