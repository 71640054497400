import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMeta from 'vue-meta';
import footer from './components/Footer';

Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.component('appFooter', footer)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");