<template>
<div id="app">
  <v-app id="inspire">
    <v-footer
      color="primary"
      dark
      padless
      bottom
      fixed
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-col
          class="primary py-4 text-center white--text"
          cols="12"
        >
          &copy; {{ new Date().getFullYear() }} — <strong>Lakas-Angkan Ministries, Inc.</strong><br/><span class="caption">Made with <span style="color: #e25555;">&#9829;</span>by Studio Crio</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</div>
</template>

<script>
export default {
  data: () => ({
    links: [
      'About Us',
      'Contact Us',
    ],
  })
}
</script>