<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-tabs v-model="tab">
          <v-tab href="#cat1">Category 1</v-tab>
          <v-tab href="#cat2">Category 2</v-tab>
          <v-tab href="#cat3">Category 3</v-tab>       
        </v-tabs>
           <v-tabs-items v-model="tab">
            <v-tab-item :key="1" value="cat1">
              <br/>
              <v-card flat>
                <v-card-title>Hebrews 1:3</v-card-title>
                <v-card-text>The Son is the radiance of God’s glory and the exact representation of his
being, sustaining all things by his powerful word. After he had provided
purification for sins, he sat down at the right hand of the Majesty in
heaven.</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="2" value="cat2">
              <br/>
              <v-card flat>
              <v-card-title>Philippians 2:7-8 </v-card-title>
                <v-card-text>But made himself nothing, taking the very nature of a servant, being made
in human likeness. And being found in appearance as a man, he humbled
himself and became obedient to death — even death on a cross!</v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="3" value="cat3">
              <br/>
              <v-card flat>
                <v-card-title>John 1:1,14</v-card-title>
                <v-card-text>In the beginning was the Word, and the Word was with God, and the Word
was God. The Word became flesh and made his dwelling among us. We
have seen his glory, the glory of the One and Only,[a] who came from the
Father, full of grace and truth.</v-card-text>
              </v-card>
            </v-tab-item>
      </v-tabs-items>
      </v-col>
      <v-col cols="12">
        
      </v-col>
      <v-col class="mb-4"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",
  metaInfo: {
    title: 'LAMI Memory Verses'
  },
  data: () => ({
    tab: "cat1",
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href: "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
